import { format } from 'date-fns';
import React, { useEffect } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import Icon from './Icon';

const DaySwitchBase = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: space-between;
  user-select: none;
`;

const DaySwitchArrow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
`;

interface DaySwitchProps {
  value?: number;
  onChange?: (value: number) => void;
  max?: number;
  date: Date;
}

const DaySwitch = ({ value, onChange, max, date }: DaySwitchProps) => {
  const [numValue, setNumValue] = React.useState(value || 0);

  useEffect(() => {
    onChange && onChange(numValue);
  }, [numValue]);

  return (
    <DaySwitchBase>
      <DaySwitchArrow onClick={() => setNumValue(numValue !== 0 ? numValue - 1 : 0)}>
        <Icon name="chevronleft" />
      </DaySwitchArrow>
      <div>
        <strong>Tag {numValue + 1}</strong>
        <br />
        {format(date, 'dd.MM.yyyy')}
      </div>
      <DaySwitchArrow onClick={() => (numValue === max ? numValue : setNumValue(numValue + 1))}>
        <Icon name="chevronright" />
      </DaySwitchArrow>
    </DaySwitchBase>
  );
};

export default DaySwitch;
