import styled, { css } from 'styled-components';

export const BaseCapsule = styled.div<{
  styles?: string;
  expanded: boolean;
  slimMode: boolean;
}>`
  background-color: '#ffffff';
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08);
  border-radius: 32px;
  color: #000;
  padding: ${props => (props.expanded ? '0px 0px' : '20px 60px')};
  font-size: 16px;
  cursor: ${props => (props.expanded ? 'normal' : 'pointer')};
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: ${props => (props.expanded ? '100%' : 'fit-content')};
  max-width: ${props => (props.slimMode || props.expanded ? '100%' : '50%')};
  transition: max-width 1s;
  max-height: 60px;
  overflow: hidden;
  position: relative;
  z-index: 8001;
  background-color: #fff;
  ${props =>
    props.styles &&
    css`
      ${props.styles}
    `}
`;

export const CapsuleAd = styled.div<{
  subscription: string;
  hidden: boolean;
}>`
  box-shadow: 0px 0px 5px 1px rgb(239, 244, 250);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #fff;
  padding: 10px;
  width: 150px;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  & > a {
    text-decoration: none;
  }
  opacity: ${props => (props.hidden ? 0 : 1)};
`;

export const CalendarContainer = styled.div<{
  vertical?: boolean;
  drawer: boolean;
  fullScreen: boolean;
}>`
  .react-calendar__viewContainer {
    display: flex;
    flex-direction: ${props => (props.vertical ? 'column' : 'row')};
    width: ${props => (props.drawer ? '250px' : '500px')};
    padding-left: ${props => (props.drawer ? '50px' : '0px')};
  }
  .react-calendar {
    width: ${props => (props.drawer ? '360px' : '500px')};
    margin-left: ${props => props.drawer && (props.fullScreen ? 'calc(50% - 180px)' : '0px')};
  }
  .react-calendar__navigation {
    display: flex;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .react-calendar__tile {
    min-height: 30px;
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  .react-calendar__tile--active.react-calendar__tile--range {
    background-color: #eff4fa;
    border-radius: 0px;
  }
  .react-calendar__tile--active.react-calendar__tile--rangeStart,
  .react-calendar__tile--active.react-calendar__tile--rangeEnd,
  .react-calendar__tile--range.react-calendar__tile--rangeStart,
  .react-calendar__tile--range.react-calendar__tile--rangeEnd {
    background-color: #314052;
    color: #fff;
    border-radius: 30px;
  }
  .react-calendar__tile--active.react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds),
  .react-calendar__tile--range.react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .react-calendar__tile--active.react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds),
  .react-calendar__tile--range.react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .react-calendar__month-view__weekNumbers {
    display: none !important;
  }
  .react-calendar__month-view {
    padding: 20px;
  }
  .react-calendar__month-view__weekdays {
    padding-bottom: 20px;
    color: #d0cfd3;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    font-weight: bold;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
  }
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
  }
`;

export const CapsuleTabContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  & > div:first-child {
    border-left: 0px solid #000;
    padding-left: 40px;
  }
`;

export const BookButton = styled.button`
  background-color: #a32564;
  border-radius: 32px;
  border: 0px;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  justify-content: flex-end;
`;

export const ContentRow = styled.div<{ align?: string }>`
  margin: 10px 0px;
  text-align: ${props => (props.align ? props.align : 'center')};
`;

export const ClickOverlay = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: transparent;
  width: 100%;
  display: ${props => (props.open ? 'block' : 'none')};
  height: 100%;
  z-index: 7999;
`;

export const CapsuleTabContentWrapper = styled.div<{
  leftPosition: number | undefined;
  topPosition: number | undefined;
  expanded: boolean;
  width: string | number | undefined;
  covered: boolean;
  fullScreen: boolean | undefined;
}>`
border: solid 1 rgba(0,0,0,0.1);
  background-color: #ffffff;
  height: ${props => (props.fullScreen ? '100%' : 'auto')};
  border-radius: ${props => (props.fullScreen ? '0px' : '32px')};
  position: ${props => (props.fullScreen ? 'fixed' : 'absolute')};
  box-sizing: border-box;
  top: ${props => props.topPosition && props.topPosition}px;
  left: ${props => props.leftPosition}px;
  z-index: ${props => (props.covered ? 9999 : 8000)};
  visibility: ${props => (props.expanded ? 'visible' : 'hidden')};
  padding-top: ${props => (props.covered ? '0px' : '62px')};
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: ${props => props.width};
  box-: 0px 5px 5px 1px rgb(239, 244, 250);
  overflow: hidden;
  overflow-y: ${props => (props.fullScreen ? 'scroll' : 'hidden')};
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const CapsuleTabContentHeader = styled.div`
  background-color: #eff4fa;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
`;

export const CapsuleTabContentBody = styled.div`
  background-color: #ffffff;
  padding: 20px;
  overflow-y: none;
  font-family: 'Roboto', sans-serif;
`;
