import React, { useEffect, useRef } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';

const Button = styled.button<{ onClick: () => void; disabled?: boolean }>`
  background-color: transparent;
  border-radius: 32px;
  border-width: 2px;
  border-color: ${props => (props.disabled ? '#ccc' : '#666')};
  border-style: solid;
  width: 25px;
  height: 25px;
  color: ${props => (props.disabled ? '#ccc' : '#666')};
  font-size: 16px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  text-align: center;
  text-weight: bold;
`;

const BaseNumField = styled.input<{ disabled?: boolean }>`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  text-align: center;
  border: 0px;
  border-bottom: 1px solid ${props => (props.disabled ? '#ccc' : '#666')};
  text-weight: bold;
  font-size: 16px;
  margin: 0px 10px;
  width: 50px;
  padding: 3px;
  background-color: transparent;
  color: ${props => (props.disabled ? '#ccc' : '#666')};
`;

interface NumFieldProps {
  value: number;
  min?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

const NumField = ({ value, min, disabled, onChange }: NumFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const onClick = (inc: boolean) => {
    if (inc) {
      onChange && onChange(value + 1);
    } else {
      onChange && onChange(value !== (min || 0) ? value - 1 : min || 0);
    }
  };

  return (
    <>
      <Button disabled={disabled} onClick={() => onClick(false)}>
        -
      </Button>
      <BaseNumField
        type="number"
        ref={ref}
        disabled={disabled}
        onClick={() => ref.current?.select()}
        onChange={e => {
          const newVal = parseInt(e.target.value);
          if (newVal >= (min || 0)) {
            onChange && onChange(parseInt(e.target.value));
          }
        }}
        value={value}
      />
      <Button disabled={disabled} onClick={() => onClick(true)}>
        +
      </Button>
    </>
  );
};

export default NumField;
