import React from 'react';
import styled from 'styled-components';

const icons: any = {
  meeting_room: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29_7672)">
        <rect width="24" height="24" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2V12H17V2H7ZM5 1V12C4.44772 12 4 12.4477 4 13V16C4 16.5523 4.44772 17 5 17H8.51816L5.14836 22.4759C4.85891 22.9463 5.00556 23.5622 5.47592 23.8517C5.94628 24.1411 6.56223 23.9945 6.85168 23.5241L10.8517 17.0241C10.8566 17.0161 10.8614 17.0081 10.8661 17H11V21.125C11 21.6082 11.4477 22 12 22C12.5523 22 13 21.6082 13 21.125V17H13.134C13.1386 17.0081 13.1434 17.0161 13.1484 17.0241L17.1484 23.5241C17.4378 23.9945 18.0538 24.1411 18.5241 23.8517C18.9945 23.5622 19.1411 22.9463 18.8517 22.4759L15.4819 17H19C19.5523 17 20 16.5523 20 16V13C20 12.4477 19.5523 12 19 12V1C19 0.447715 18.5523 0 18 0H6C5.44772 0 5 0.447716 5 1ZM18 15V14H6L6 15H12H18ZM8 5C8 4.44772 8.44772 4 9 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H9C8.44772 6 8 5.55228 8 5ZM9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H12C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7H9Z"
          fill="#082B42"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_7672">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  ),
  people: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7ZM9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM16 2C15.4477 2 15 2.44772 15 3C15 3.55228 15.4477 4 16 4C17.6569 4 19 5.34315 19 7C19 8.65685 17.6569 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12C18.7614 12 21 9.76142 21 7C21 4.23858 18.7614 2 16 2ZM2.60761 17.0902C2.13157 17.6428 2 18.3604 2 19V22.5C2 23.0523 1.55228 23.5 1 23.5C0.447715 23.5 0 23.0523 0 22.5V19C0 18.1396 0.168434 16.8572 1.09239 15.7848C2.04011 14.6848 3.61095 14 6 14H13C14.0039 14 15.2248 14.5337 16.1721 15.3375C17.1427 16.1611 18 17.4172 18 19V23C18 23.5523 17.5523 24 17 24C16.4477 24 16 23.5523 16 23V19C16 18.1828 15.5574 17.4389 14.8781 16.8625C14.1754 16.2663 13.3964 16 13 16H6C3.98905 16 3.05989 16.5652 2.60761 17.0902ZM19 14C18.4477 14 18 14.4477 18 15C18 15.5523 18.4477 16 19 16C20.5138 16 22 17.2161 22 19V23C22 23.5523 22.4477 24 23 24C23.5523 24 24 23.5523 24 23V19C24 15.9839 21.4862 14 19 14Z"
        fill="#082B42"
      />
    </svg>
  ),
  coffee: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path d="M10.5 9H2C1.44772 9 1 9.44772 1 10V12C1 14.6667 2.9 20 10.5 20" stroke="#082B42" strokeWidth="2" />
      <path d="M10 9H18.5C19.0523 9 19.5 9.44772 19.5 10V12C19.5 14.6667 17.6 20 10 20" stroke="#082B42" strokeWidth="2" />
      <path d="M20 11C20.8333 11.1667 23 12 23 14C23 16 20 17 18.5 16.5" stroke="#082B42" strokeWidth="2" />
      <path d="M6.5 2V2C5.61111 3.18519 5.61111 4.81481 6.5 6V6" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M11 2V2C10.1111 3.18519 10.1111 4.81481 11 6V6" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 2V2C15.1111 3.18519 15.1111 4.81481 16 6V6" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M2 23H18.5" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  doublebed: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29_7698)">
        <rect width="24" height="24" />
        <rect x="3" y="1" width="7" height="5" rx="1" stroke="#082B42" strokeWidth="2" />
        <rect x="14" y="1" width="7" height="5" rx="1" stroke="#082B42" strokeWidth="2" />
        <path
          d="M1 22V13.8028L3.2766 17.2177C4.05753 18.3891 5.54797 18.8599 6.86009 18.3496L23 12.073V22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22ZM23 9.13278V11L22.6376 10.068L6.1352 16.4856C5.69782 16.6557 5.20101 16.4987 4.9407 16.1083L2.03727 11.7531L23 9.13278ZM1.2164 11.8557L1 12V11.8828L1.2164 11.8557Z"
          stroke="#082B42"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_7698">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  ),
  calendar: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C5 0.447715 5.44772 0 6 0H7C7.55228 0 8 0.447715 8 1V3H15V1C15 0.447715 15.4477 0 16 0H17C17.5523 0 18 0.447715 18 1V3H19C20.6569 3 22 4.34315 22 6V21C22 22.6569 20.6569 24 19 24H4C2.34315 24 1 22.6569 1 21V6C1 4.34315 2.34315 3 4 3H5V1ZM19 5H4C3.44772 5 3 5.44772 3 6V21C3 21.5523 3.44772 22 4 22H19C19.5523 22 20 21.5523 20 21V6C20 5.44771 19.5523 5 19 5ZM8 8.5C8 9.32843 7.32843 10 6.5 10C5.67157 10 5 9.32843 5 8.5C5 7.67157 5.67157 7 6.5 7C7.32843 7 8 7.67157 8 8.5ZM6.5 15C7.32843 15 8 14.3284 8 13.5C8 12.6716 7.32843 12 6.5 12C5.67157 12 5 12.6716 5 13.5C5 14.3284 5.67157 15 6.5 15ZM8 18.5C8 19.3284 7.32843 20 6.5 20C5.67157 20 5 19.3284 5 18.5C5 17.6716 5.67157 17 6.5 17C7.32843 17 8 17.6716 8 18.5ZM11.5 10C12.3284 10 13 9.32843 13 8.5C13 7.67157 12.3284 7 11.5 7C10.6716 7 10 7.67157 10 8.5C10 9.32843 10.6716 10 11.5 10ZM13 13.5C13 14.3284 12.3284 15 11.5 15C10.6716 15 10 14.3284 10 13.5C10 12.6716 10.6716 12 11.5 12C12.3284 12 13 12.6716 13 13.5ZM11.5 20C12.3284 20 13 19.3284 13 18.5C13 17.6716 12.3284 17 11.5 17C10.6716 17 10 17.6716 10 18.5C10 19.3284 10.6716 20 11.5 20ZM18 8.5C18 9.32843 17.3284 10 16.5 10C15.6716 10 15 9.32843 15 8.5C15 7.67157 15.6716 7 16.5 7C17.3284 7 18 7.67157 18 8.5ZM16.5 15C17.3284 15 18 14.3284 18 13.5C18 12.6716 17.3284 12 16.5 12C15.6716 12 15 12.6716 15 13.5C15 14.3284 15.6716 15 16.5 15Z"
        fill="#082B42"
      />
    </svg>
  ),
  flipchart: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        d="M2 2H22C22.5523 2 23 2.44772 23 3V16C23 16.5523 22.5523 17 22 17H2C1.44772 17 1 16.5523 1 16V3C1 2.44772 1.44772 2 2 2Z"
        stroke="#082B42"
        strokeWidth="2"
        stroke-linejoin="round"
      />
      <path d="M2 13C2.4 13 15.5 13 22 13" stroke="#082B42" strokeWidth="2" />
      <path d="M6.5 23H16.5" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M9 17V23" stroke="#082B42" strokeWidth="2" />
      <path d="M14 17V23" stroke="#082B42" strokeWidth="2" />
    </svg>
  ),
  download: (color: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1C6.21164 1 5.93731 1.12448 5.74742 1.3415L2.24742 5.3415C2.08792 5.52379 2 5.75778 2 6V22H10.5C11.0523 22 11.5 21.5523 11.5 21C11.5 20.4477 11.0523 20 10.5 20H4V6.37573L6.95377 3H17.5V6.5C17.5 7.05228 17.9477 7.5 18.5 7.5C19.0523 7.5 19.5 7.05228 19.5 6.5V1H6.5ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H14.5C15.0523 9 15.5 8.55228 15.5 8C15.5 7.44772 15.0523 7 14.5 7H7ZM6 12C6 11.4477 6.44772 11 7 11H12.5C13.0523 11 13.5 11.4477 13.5 12C13.5 12.5523 13.0523 13 12.5 13H7C6.44772 13 6 12.5523 6 12ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H10.5C11.0523 17 11.5 16.5523 11.5 16C11.5 15.4477 11.0523 15 10.5 15H7ZM18.5 11C19.0523 11 19.5 11.4477 19.5 12V20.5568L21.4188 19.1863C21.8682 18.8652 22.4927 18.9693 22.8138 19.4187C23.1348 19.8682 23.0307 20.4927 22.5813 20.8137L19.0813 23.3137C18.7336 23.5621 18.2665 23.5621 17.9188 23.3137L14.4188 20.8137C13.9694 20.4927 13.8653 19.8682 14.1863 19.4187C14.5073 18.9693 15.1318 18.8652 15.5813 19.1863L17.5 20.5568V12C17.5 11.4477 17.9477 11 18.5 11Z"
        fill={color ? color : '#082B42'}
      />
    </svg>
  ),
  chevronup: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
      <path d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  chevrondown: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  chevronleft: (color: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(270deg)' }}>
      <path
        d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14"
        stroke={color ? color : '#082B42'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  chevronright: (color: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }}>
      <path
        d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14"
        stroke={color ? color : '#082B42'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  checkmark: (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"/></svg>
  ),
  error: (
    <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg" ><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" fill='#D72828'/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" fill="#E6E6E6"/></g></g></g></svg>
  ),
  place: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="surface1">
    <path d="M 12 0 C 7.40625 0 3.671875 3.738281 3.671875 8.328125 C 3.671875 12.75 11.226562 23.078125 11.550781 23.515625 L 11.851562 23.925781 C 11.886719 23.972656 11.941406 24 12 24 C 12.058594 24 12.113281 23.972656 12.152344 23.925781 L 12.449219 23.515625 C 12.773438 23.078125 20.328125 12.75 20.328125 8.328125 C 20.328125 3.738281 16.59375 0 12 0 Z M 12 5.34375 C 13.644531 5.34375 14.984375 6.683594 14.984375 8.328125 C 14.984375 9.972656 13.644531 11.3125 12 11.3125 C 10.355469 11.3125 9.015625 9.972656 9.015625 8.328125 C 9.015625 6.683594 10.355469 5.34375 12 5.34375 Z M 12 5.34375 "/>
    </g>
    </svg>
  ),
  search: (color: string) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
<g id="surface1">
<path stroke={color} fill={color} d="M 18.039062 14.878906 L 24 20.925781 L 21 24 L 14.886719 18.035156 C 13.347656 18.996094 11.566406 19.503906 9.75 19.5 C 4.363281 19.5 0 15.125 0 9.75 C 0 4.363281 4.375 0 9.75 0 C 15.136719 0 19.5 4.375 19.5 9.75 C 19.503906 11.5625 18.996094 13.339844 18.039062 14.878906 Z M 2.996094 9.679688 C 2.996094 13.398438 6.015625 16.429688 9.746094 16.429688 C 13.464844 16.429688 16.496094 13.40625 16.496094 9.679688 C 16.496094 5.957031 13.472656 2.929688 9.746094 2.929688 C 6.027344 2.929688 2.996094 5.949219 2.996094 9.679688 Z M 2.996094 9.679688 "/>
</g>
</svg>
  )
};

interface IconProps {
  name?: string;
  color?: string;
}

const Icon = ({ name, color }: IconProps) => {
  return <>{name && icons && (typeof icons[name] === 'function' ? icons[name](color) : icons[name])}</>;
};

export default Icon;
