import { isAfter, isEqual, isBefore, parseISO } from "date-fns/fp"
import Calendar from "react-calendar"
import { CalendarContainer, CapsuleTabContentBody } from "../CapsuleComponents"
import { createRef, useEffect, useRef, useState } from "react"
import { QuickPriceCalculator, QuickPriceCalculatorType } from "../../../semshared/pricelist/quickprice";
import { AvailabilityType } from "../../../semshared/availability/availability";

interface CalendarSectionProps {
  onCalChanged: any
  calValue: any
  fullScreen: boolean
  drawer: boolean
  vertical: boolean
  quickPriceCalculator: QuickPriceCalculatorType | null
  availabilityChecker: AvailabilityType | null,
}

const CalendarSection = ({
  onCalChanged,
  calValue,
  fullScreen,
  drawer,
  vertical,
  quickPriceCalculator,
  availabilityChecker,
}: CalendarSectionProps) => {

  const ref = useRef<any>()

  const getDisabledRanges = (date: any) => {
    if (isBefore(new Date(), date)) {
      return true;
    }
    return false;
  };

  const [today, setToday] = useState(new Date());
  return (
    <CapsuleTabContentBody>
      <CalendarContainer vertical={vertical} fullScreen={fullScreen} drawer={drawer}>
        <Calendar
          inputRef={ref}
          locale="de-DE"
          onChange={onCalChanged}
          showWeekNumbers
          value={calValue}
          selectRange={true}
          showDoubleView={true}
          showFixedNumberOfWeeks={false}
          showNeighboringMonth={false}
          tileDisabled={({ date }) => getDisabledRanges(date)}
          // fixes the issue to jump to next month when clicking on next month
          activeStartDate={today} // pass in state var in activeStartDate
          onActiveStartDateChange={data => {
            const { value, activeStartDate, action } = data;
            if (action === 'next' || action === 'next2') {
              setToday(new Date(activeStartDate as Date));
            }
            if (action === 'prev' || action === 'prev2') {
              setToday(new Date(activeStartDate as Date));
            }
          }}
        />
      </CalendarContainer>
    </CapsuleTabContentBody>
  );
};

export default CalendarSection;
